<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>/</span></li>
        <li class="title" :style="'color:' + color">{{ params.name }}</li>
      </ul>
    </div>
    <div class="content">
      <template v-if="params.type == 1">
        <ul class="left_box" :style="'border:1px solid' + color">
          <div class="title" :style="'background:' + color">{{ dates.navigationName }}</div>
          <li v-for="(item, index) in dates.witem" :key="index" :class="index == selectNum ? 'active' : ''" :style="index == selectNum ? 'border-left:4px solid' + color : ''" @click="selectItem(index)">
            {{ item.navigationName }}
          </li>
        </ul>
        <div class="right_box" v-if="isList">
          <div class="title">
            <p :style="'border-bottom:1px solid' + color + ';color:' + color">{{ selectNumTitle }}</p>
          </div>
          <ul>
            <li v-for="(item, index) in list" :key="index" :class="(index + 1) % 5 == 0 ? 'border_b' : ''" @click="goDetails(item.id)">
              <span class="circular" :style="'background:' + color"></span>
              <span class="text">{{ item.journalisTitle }}</span>
              <span class="time">{{ toDate(dates.createTime, "yyyy-MM-dd") }}</span>
            </li>
          </ul>
          <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
        <div class="list_details" v-else>
          <div class="details_box">
            <div class="title" :style="'color:' + color">{{ details.journalisTitle }}</div>
            <div class="title_m">
              <span>信息来源：{{ details.message }}</span>
              <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
            </div>
            <div class="ql-editor" v-html="text"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="details_box">
          <div class="title">{{ details.journalisTitle }}</div>
          <div class="title_m">
            <span>信息来源：{{ details.message }}</span>
            <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
          </div>
          <div class="ql-editor" v-html="text"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  selectNavigaionList,
  getNavigationQueryId,
  websiteListGetJournalismId,
  getTextarea,
} from "@/api/template";
export default {
  name: "Transfer",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 10,
      },
      total: 10,
      dates: [],
      details: {},
      leftList: [],
      list: [],
      selectNum: 0,
      selectNumTitle: "",
      isList: false,
      text: "",
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    this.params = this.$route.params
    window.sessionStorage.setItem("currentActive", this.params.index);
    window.sessionStorage.setItem("navigationValue", JSON.stringify(this.params));
    this.init();
  },
  methods: {
    init() {
      if (this.params.type == 1) {
        getNavigationQueryId(this.params.id).then((res) => {
          if (this.params.type == 1) {
            if (res.data.witem.length > 0) {
              this.query.navigationId = res.data.witem[0].id;
              this.query.navigationType = res.data.witem[0].navigationType;
              this.getSelectNavigaionList();
            }
            this.dates = res.data;
            this.list = [];
            this.selectNumTitle =
              this.dates.witem[this.selectNum].navigationName;
          } else {
            this.details = res.data;
          }
        }).catch(() => {});
      } else {
        websiteListGetJournalismId(this.params.id).then((res) => {
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }).catch(() => {});
      }
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
    getSelectNavigaionList() {
      selectNavigaionList(this.query).then((res) => {
        if (res.data.records) {
          this.list = res.data.records;
          this.isList = true;
        } else {
          this.details = res.data;
          this.isList = false;
          this.getTextareaFun(res.data.journalisContent);
        }
        this.total = res.data.total;
      }).catch(() => {});
    },
    selectItem(index) {
      this.selectNumTitle = this.dates.witem[index].navigationName;
      if (this.selectNum == index) {
        return false;
      } else {
        this.selectNum = index;
        this.query.current = 1;
        this.query.navigationId = this.dates.witem[index].id;
        this.query.navigationType = this.dates.witem[index].navigationType;
        this.list = [];
        this.getSelectNavigaionList();
      }
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.getSelectNavigaionList();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.getSelectNavigaionList();
    },
    goIndex() {
      this.$router.push("./template-third");
      window.sessionStorage.setItem("currentActive", "-100");
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/third-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.l_nav {
  height: 40px;
  background-color: #f7f7f7;
  border-bottom: 1px #d2d2d2 solid;
  ul {
    width: 1200px;
    margin: 10px auto 0;
    li {
      float: left;
      line-height: 40px;
      span {
        padding: 0 10px;
      }
    }
    .title {
      color: #00a384;
    }
  }
}
.content {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  width: 1160px;
  min-height: 420px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  .left_box {
    width: 233px;
    background: #fff;
    font-size: 16px;
    line-height: 40px;
    border: 1px solid #00a384;
    padding-bottom: 20px;
    border-top: none;
    .title {
      background: #00a384;
      color: #fff;
      padding: 0 16px;
    }
    li {
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      border: 1px #dddddd solid;
      margin: 20px 20px 0 20px;
      cursor: pointer;
    }
    .active {
      background: #f5f5f5;
      border: 1px #dddddd solid;
      border-left: 4px solid #00a384;
    }
  }
  .right_box {
    width: 905px;
    margin-left: 20px;
    .title {
      border-bottom: 1px #d7d7d7 solid;
      height: 40px;
      p {
        float: left;
        border-bottom: 1px #00a384 solid;
        padding: 0 16px;
        line-height: 40px;
        font-size: 16px;
        color: #00a384;
      }
    }
    ul {
      padding: 20px;
      li {
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        .circular {
          float: left;
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background: #00a384;
          margin-top: 13px;
          margin-right: 10px;
        }
        .text {
          float: left;
          width: 750px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .time {
          float: right;
        }
      }
      .border_b {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .list_details {
    width: 905px;
    margin-left: 20px;
  }
  .details_box {
    width: 100%;
    .title {
      color: #00a384;
      font-size: 26px;
      text-align: center;
      line-height: 80px;
      font-weight: bold;
    }
    .title_m {
      text-align: center;
      border-bottom: 1px solid #d7d7d7;
      padding: 20px;
      font-size: 14px;
      span {
        margin-right: 30px;
      }
    }
  }

  .ql-editor {
    padding: 20px 0;
    line-height: 30px;
  }
}
</style>
